// Controllo se il form newsletter è presente nella pagina
const newsletterForms = document.querySelectorAll('.newsletter-subscription');


if (newsletterForms.length > 0) {
    newsletterForms.forEach((newsletterForm) => {
        // Se esiste recupero il campo di input della mail
        const newsletterInput = newsletterForm.querySelector('[name="newsletter_email"]');

        // Se esiste allora mi aggancio all'evento di focus
        newsletterInput.addEventListener('focus', () => {
            // Recupero il fieldset collasabile
            const fieldset = newsletterForm.querySelector('.fieldset-collapse');

            // Se esiste allora aggiungo la classe show
            if (fieldset) {
                fieldset.classList.add('show');
            }
        });

    });
}