const videoHeroes = document.getElementsByClassName('hero-video');

for (var i = 0; i < videoHeroes.length; i++) {
	let videoHero = videoHeroes[i];
    let video = videoHero.querySelector('video');
    let muteButton = videoHero.querySelector('.video-mute');

    muteButton.addEventListener('click', function() {
        if (video.muted) {
            video.muted = false;
            muteButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M342.9 193.6c-7.8-3.8-17.5-.5-21.3 7.5-3.8 8-.4 17.5 7.5 21.3C343.2 229.2 352 242.1 352 256c0 13.9-8.8 26.8-22.9 33.6-8 3.8-11.3 13.4-7.5 21.3 3.9 8.1 13.5 11.3 21.3 7.5C368.3 306.3 384 282.4 384 256s-15.8-50.3-41.1-62.4zM231.8 64c-5.9 0-11.9 2.2-16.8 7.1L126.1 160H24c-13.3 0-24 10.7-24 24v144c0 13.3 10.7 24 24 24h102.1l89 89c4.9 4.9 10.9 7.1 16.8 7.1 12.3 0 24.2-9.5 24.2-24V88C256 73.5 244.1 64 231.8 64zM224 404.7L139.3 320H32V192h107.3L224 107.3v297.3zm256-148.7c0-66.1-34-126.6-88.8-157.9-7.7-4.4-17.6-1.8-22 5.9-4.5 7.7-1.8 17.4 6 21.9 44.8 25.6 72.6 75.4 72.6 130.1s-27.8 104.6-72.6 130.1c-7.7 4.4-10.4 14.2-6 21.9 4.3 7.4 14.1 10.4 22 5.9C446 382.6 480 322.1 480 256z"/></svg>';
        } else {
            video.muted = true;
            muteButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M454.6 256l55-55c3.1-3.1 3.1-8.2 0-11.3l-11.3-11.3c-3.1-3.1-8.2-3.1-11.3 0L432 233.4l-55-55c-3.1-3.1-8.2-3.1-11.3 0l-11.3 11.3c-3.1 3.1-3.1 8.2 0 11.3L409.4 256l-55 55c-3.1 3.1-3.1 8.2 0 11.3l11.3 11.3c3.1 3.1 8.2 3.1 11.3 0L432 278.6l55 55c3.1 3.1 8.2 3.1 11.3 0l11.3-11.3c3.1-3.1 3.1-8.2 0-11.3L454.6 256zM231.8 64c-5.9 0-11.9 2.2-16.8 7.1L126.1 160H24c-13.3 0-24 10.7-24 24v144c0 13.3 10.7 24 24 24h102.1l89 89c4.9 4.9 10.9 7.1 16.8 7.1 12.3 0 24.2-9.5 24.2-24V88C256 73.5 244.1 64 231.8 64zM224 404.7l-75.3-75.3-9.4-9.4H32V192h107.3l9.4-9.4 75.3-75.3v297.3z"/></svg>';
        }
    });

}