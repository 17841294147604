$(() => {
	if ($(".block-logoscarousel").length > 0) {

		$(".block-logoscarousel").each((i, el) => {
			let logosCarousel = $(el).find(".owl-logoscarousel");

			logosCarousel.owlCarousel({
				loop: false,
				nav: false,
				dots: false,
				autoWidth: false,
				responsive: {
					0: {
						items: 3,
						margin: 15
					},
					576: {
						items: 4,
						margin: 15
					},
					768: {
						items: 5,
						margin: 30
					},
					992: {
						items: 6,
						margin: 60
					},
					1200: {
						items: 7,
						margin: 60
					},
				},
			});
		});

	}
});
