const contactForms = document.getElementsByClassName('contact-form');

for (var i = 0; i < contactForms.length; i++) {
	let contactForm = contactForms[i];

	contactForm.addEventListener('submit', function(e) {
		e.preventDefault();
	
		var form = e.target;
		var formFieldset = form.querySelector('fieldset');
	
		if (form.reportValidity() && !form.classList.contains('disabled') && !formFieldset.disabled) {
			
			var formMessageWrapper = form.querySelector('.form-notify');
			var ajaxUrl = WPURLS.ajaxUrl + '?action=' + form.getAttribute('data-action');
			var formData = [];

            formData = new FormData(form);
	
			disableForm(form, formFieldset);
			changeFormMessage(formMessageWrapper, WPURLS.mail_message.sending, 'info');
			showFormMessage(formMessageWrapper);
	
			var xhr = new XMLHttpRequest();
			xhr.open('POST', ajaxUrl, true);
	
			xhr.onreadystatechange = function() {
	
				if (xhr.readyState === 4) {
	
					enableForm(form, formFieldset);
		
					if (xhr.status === 200) {
                        console.log(xhr.responseText);
						var data = JSON.parse(xhr.responseText);

                        
		
						if (data.success) {
	
							changeFormMessage(formMessageWrapper, data.message, 'success');
							showFormMessage(formMessageWrapper);
	
							if (data.redirect) {
								setTimeout(function() {
									window.location.href = data.redirect;
								}, 1000);
							}
	
							resetFormMessage(formMessageWrapper, 20000);
	
						} else {
							var errorString = data.message ? data.message : WPURLS.mail_message.error;
	
							changeFormMessage(formMessageWrapper, errorString, 'error');
							showFormMessage(formMessageWrapper);
							resetFormMessage(formMessageWrapper);
	
						}
	
					} else {
	
						changeFormMessage(formMessageWrapper, WPURLS.mail_message.error, 'error');
						showFormMessage(formMessageWrapper);
						resetFormMessage(formMessageWrapper);
	
					}
				}
			};
			
			xhr.send(formData);
	
			e.preventDefault();
		}
	
	}, false);

}

function changeFormMessage(messageWrapper, message, type) {

	messageWrapper.className = 'form-notify ' + type;
	messageWrapper.innerHTML = message;
	messageWrapper.style.display = 'block';

}

function showFormMessage(messageWrapper) {

	if(messageWrapper.style.display !== 'block') {
		messageWrapper.style.display = 'block';
	}

}

function hideFormMessage(messageWrapper) {

	if(messageWrapper.style.display !== 'none') {
		messageWrapper.style.display = 'none';
	}

}

function resetFormMessage(messageWrapper, timeout = 5000) {

	setTimeout(function() {
		changeFormMessage(messageWrapper, '', '');
		hideFormMessage(messageWrapper);
	}, timeout);

}

function disableForm(form, fieldset) {

	fieldset.disabled = true;
	form.classList.add('disabled');
	
}

function enableForm(form, fieldset) {

	fieldset.disabled = false;
	form.classList.remove('disabled');

}