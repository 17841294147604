// Importa la libreria GSAP
import { gsap } from "gsap";

$(() => {
    const header = document.querySelector("#pageHeader");
	let isNavVisible = true;
	let lastScroll = window.scrollY;

	// Funzione per controllare la visibilità del menu di navigazione
	function toggleNavVisibility() {
		const currentScroll = window.scrollY;

		// Creo una costante wpAdminBarHeight che contiene l'altezza della barra di amministrazione di WordPress, se presente
		const wpAdminBarHeight = document.querySelector("#wpadminbar") ? document.querySelector("#wpadminbar").offsetHeight : 0;

		// Verifica se lo scroll è verso il basso e l'utente non è in cima alla pagina
		if (currentScroll > lastScroll && currentScroll > 100) {
			// Nascondi il menu di navigazione se è attualmente visibile
			if (isNavVisible) {
				gsap.to(header, { top: -header.offsetHeight - wpAdminBarHeight, duration: 0.15, ease: "power2.inOut" });
				isNavVisible = false;
				header.classList.remove("header--sticky");
			}
		} else {

			
			// Mostra il menu di navigazione se è attualmente nascosto
			if (!isNavVisible) {
				header.classList.add("header--sticky");
				// Se lo scroll è maggiore di 100px, mostra il menu di navigazione appena sotto la barra di amministrazione di WordPress
				gsap.to(header, { top: wpAdminBarHeight, duration: 0.15, ease: "power2.inOut" });
				isNavVisible = true;
			} 
			
			// Se lo scroll è minore di 100px, mostra il menu di navigazione appena sopra la barra di amministrazione di WordPress
			if (currentScroll < 100) {
				gsap.to(header, { top: wpAdminBarHeight, duration: 0.15, ease: "power2.inOut" });
				header.classList.remove("header--sticky");
			}
		}

		// Aggiorna la posizione dello scroll
		lastScroll = currentScroll;
	}

	// Aggiungi un listener per lo scroll della finestra
	window.addEventListener("scroll", toggleNavVisibility);

});
