$(() => {
	if ($(".block-labelscarousel").length > 0) {

		$(".block-labelscarousel").each((i, el) => {
			let labelsCarousel = $(el).find(".owl-labelscarousel");

			labelsCarousel.owlCarousel({
				loop: true,
				nav: false,
				dots: false,
				autoplay: true,
				autoWidth: true,
				autoplayTimeout: 1000,
				autoplayHoverPause:false,
				margin: 10,
				responsive: {
					0: {
						items: 3,
						autoWidth: true
					},
					576: {
						items: 4,
						autoWidth: true
					},
					768: {
						items: 6,
						autoWidth: true
					},
					992: {
						items: 8,
						autoWidth: true
					},
					1200: {
						items: 10,
						autoWidth: true
					},
				},
			});
		});

	}
});
