$(() => {
	if ($(".block-reviews").length > 0) {

		$(".block-reviews").each((i, el) => {
			let owlReviews = $(el).find(".owl-reviews");

			const navPrev =
				'<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none"><path d="M12.3031 5.68783C12.4065 5.59595 12.4754 5.48684 12.5099 5.36051C12.5443 5.23417 12.5443 5.10496 12.5099 4.97289C12.4754 4.84081 12.4122 4.72883 12.3204 4.63695C12.2285 4.54507 12.1165 4.4819 11.9844 4.44744C11.8523 4.41299 11.7231 4.41299 11.5968 4.44744C11.4705 4.4819 11.3613 4.55081 11.2695 4.65417L6.85921 9.06443C6.70991 9.21374 6.63525 9.38601 6.63525 9.58126C6.63525 9.7765 6.70991 9.94878 6.85921 10.0981L11.2695 14.5083C11.4188 14.6462 11.591 14.7151 11.7863 14.7151C11.9815 14.7151 12.1509 14.6433 12.2945 14.4997C12.4381 14.3562 12.5099 14.1868 12.5099 13.9915C12.5099 13.7963 12.4409 13.624 12.3031 13.4747L8.40969 9.58126L12.3031 5.68783Z" fill="black"/></svg>';
			const navNext =
				'<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none"><path d="M7.02118 13.4747C6.88336 13.624 6.81445 13.7963 6.81445 13.9915C6.81445 14.1868 6.88623 14.3562 7.0298 14.4997C7.17336 14.6433 7.34277 14.7151 7.53801 14.7151C7.73326 14.7151 7.90553 14.6462 8.05484 14.5083L12.4651 10.0981C12.6144 9.94878 12.6891 9.7765 12.6891 9.58126C12.6891 9.38601 12.6144 9.21374 12.4651 9.06443L8.05484 4.65417C7.90553 4.51635 7.73326 4.44744 7.53801 4.44744C7.34277 4.44744 7.17336 4.51923 7.0298 4.66279C6.88623 4.80635 6.81445 4.97576 6.81445 5.171C6.81445 5.36625 6.88336 5.53852 7.02118 5.68783L10.9146 9.58126L7.02118 13.4747Z" fill="black"/></svg>';

			owlReviews.owlCarousel({
				loop: false,
				nav: true,
				dots: false,
				navText: [navPrev, navNext],
				navContainer: ".owl-reviews-nav",
				autoplay:true, 
				autoplayTimeout: 1500,
				autoplayHoverPause: true,	
				responsive: {
					0: {
						items: 1,
						margin: 30,
						autoWidth: true
					},
					768: {
						items: 2,
						margin: 30,
						autoWidth: false
					},
					992: {
						items: 3,
						margin: 30
					}
				},
			});
		});

	}
});
