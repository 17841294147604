const mediaQuery = [window.matchMedia("(max-width: 767px)")];
const toggle = document.querySelector(".navbar-toggler") !== null ? document.querySelector(".navbar-toggler") : null;
const pageHeader = document.querySelector("#pageHeader") !== null ? document.querySelector("#pageHeader") : null;
const headerBackdrop = document.querySelector("#headerBackdrop") !== null ? document.querySelector("#headerBackdrop") : null;

if(toggle) {

    toggle.addEventListener("click", function (e) {
        toggleMenu();
    });

    headerBackdrop.addEventListener("click", function (e) {
        toggle.click();
    });
    
    function toggleMenu() {
        pageHeader.classList.toggle("is-active");
        headerBackdrop.classList.toggle("is-active");
        toggle.classList.toggle("is-active");
        
        setTimeout(function () {
            document.querySelector("body").classList.toggle("no-scroll");
        }, 100);
    }
}


subMenu(mediaQuery[0]);

function subMenu(event) {
    if (event.matches) {
        const menuHasChildren = document.querySelectorAll(".navbar-collapse .navbar-nav .menu-item-has-children");

        menuHasChildren.forEach(function (el, i) {
            el.querySelector("a").addEventListener("click", function (e) {
                e.preventDefault();

                // Rimuove la classe 'show' da tutti gli elementi '.child-menu' tranne quello cliccato
                const allChildMenus = document.querySelectorAll(".child-menu.show");
                allChildMenus.forEach(function (childMenu) {
                    if (childMenu !== el.querySelector(".child-menu")) {
                        childMenu.classList.remove("show");
                    }
                });

                // Aggiunge o rimuove la classe 'show' all'elemento cliccato
                el.querySelector(".child-menu").classList.toggle("show");
            });
        });
    }
}