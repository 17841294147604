import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


document.addEventListener("DOMContentLoaded", () => {
    // Recupero la megahero
    const megahero = document.querySelector('.page-megahero');
    const mediaQuery = [window.matchMedia("(min-width: 992px)")];

    window.addEventListener('load', function() {
        if (megahero) {
            // Al caricamento della pagina tutte le megahero-card si spostano verso il basso usando gsap
            gsap.from('.megahero-card-1,.megahero-card-2,.megahero-card-3,.megahero-card-4,.megahero-card-5,.megahero-card-6,.megahero-card-7', {
                y: 400,
                duration: 1,
                stagger: .2,
                ease: 'power4.out'
            });
        }
    });

    // Controllo se esiste megahero all'interno della pagina
    if (megahero) {

        scrollCards(mediaQuery[0]);

        // Allo scroll del mouse cambio il colore di sfondo della megahero
        // Cambio il colore di sfondo della megahero al passaggio
        gsap.to('.page-megahero', {
            backgroundColor: '#fff',
            scrollTrigger: {
                trigger: megahero,
                endTrigger: megahero.querySelector(".megahero-heading"),
                start: 'top top',
                end: 'top top',
                scrub: .5,
                invalidateOnRefresh: true
            }
        });

    }

    function scrollCards (event) {

        if (event.matches) {
            
            gsap.to('.megahero-card-1,.megahero-card-5,.megahero-card-6,.megahero-card-7', {
                y: -400,
                scrollTrigger: {
                    trigger: megahero,
                    endTrigger: megahero.querySelector(".megahero-footer"),
                    start: 'top top',
                    scrub: .1,
                    invalidateOnRefresh: true
                }
            });

            gsap.to('.megahero-card-2,.megahero-card-4', {
                y: -250,
                scrollTrigger: {
                    trigger: megahero,
                    endTrigger: megahero.querySelector(".megahero-footer"),
                    start: 'top top',
                    end: 'bottom top',
                    scrub: .5,
                    invalidateOnRefresh: true
                }
            });

            gsap.to('.megahero-card-3', {
                y: -150,
                scrollTrigger: {
                    trigger: megahero,
                    endTrigger: megahero.querySelector(".megahero-footer"),
                    start: 'top top',
                    end: 'top top',
                    scrub: true,
                    scrub: .5,
                    invalidateOnRefresh: true
                }
            });
        }
        
    }

});

