
document.addEventListener('DOMContentLoaded', function() {
    const searchInput = document.getElementById('search-query');
    const resultsContainer = document.getElementById('search-results');

    function loadResults(query = '') {
        // Effettua la richiesta AJAX
        fetch(WPURLS.ajaxUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                'action': 'glossary_search',
                'search_query': query
            })
        })
        .then(response => response.json())
        .then(json => {
            resultsContainer.innerHTML = '';

            if (json.success) {
                
                resultsContainer.innerHTML = `<div class="accordion glossary-accordion glossary-search-results" id="glossaryAccordion"></div>`;

                json.data.forEach(post => {
                    const postElement = document.createElement('div');
                    postElement.className = "accordion-item";

                    postElement.innerHTML = `
                        <div class="accordion-header" id="heading${post.ID}" data-accordion-anchor="letter${post.title.charAt(0).toUpperCase()}">
                            <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse${post.ID}" aria-expanded="false" aria-controls="collapse${post.ID}">
                                ${post.title}
                            </button>
                        </div>

                        <div class="accordion-collapse collapse" id="collapse${post.ID}" data-bs-parent="#glossaryAccordion">
                            <div class="accordion-body">
                                ${post.content}
                            </div>
                        </div>
                    `;

                    resultsContainer.querySelector('.glossary-accordion').appendChild(postElement);
                });
            } else {
                resultsContainer.innerHTML = `<p>${json.data.notFoundMessage}</p>`;
            }
            
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    if (searchInput && resultsContainer) {
        searchInput.addEventListener('input', function() {
            const searchQuery = searchInput.value;
            loadResults(searchQuery);
        });

        loadResults();
    }

    
});
